<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="115px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="客户中文品名" prop="cusProductCnName">
            <el-input
              v-model="queryForm.cusProductCnName"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="客户英文品名" prop="cusProductEnName">
            <el-input
              v-model="queryForm.cusProductEnName"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="申报中文品名" prop="reportCnName">
            <el-input
              v-model="queryForm.reportCnName"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="申报英文品名" prop="reportEnName">
            <el-input
              v-model="queryForm.reportEnName"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="中国海关编码" prop="hsCode">
            <el-input
              v-model="queryForm.hsCode"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item label="进口国海关编码" prop="importHsCode">
            <el-input
              v-model="queryForm.importHsCode"
              clearable
              placeholder="请输入"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item v-show="fold" label="关税税率" prop="rateOfDuty">
            <el-input-number
              v-model="queryForm.rateOfDuty"
              :max="100"
              :min="0"
              :precision="2"
              size="medium"
              :step="0.1"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item v-show="fold" label="风险评估" prop="riskType">
            <el-select
              v-model="queryForm.riskType"
              clearable
              placeholder="请选择类型"
              style="width: 180px"
            >
              <el-option
                v-for="item in riskTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-show="fold" label="是否可承接" prop="acceptability">
            <el-select
              v-model="queryForm.acceptability"
              clearable
              placeholder="请选择类型"
              style="width: 180px"
            >
              <el-option
                v-for="item in acceptabilityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-show="fold" label="包税附加费" prop="surcharge">
            <el-input-number
              v-model="queryForm.surcharge"
              :max="100"
              :min="0"
              :precision="2"
              size="medium"
              :step="0.1"
              style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">合并</span>
              <span v-else>展开</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': !fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <!-- <el-button  size="mini" type="primary" @click="addOrEdit">
          新增
        </el-button> -->
        <el-button
          :disabled="!queryForm.idList.length > 0"
          size="mini"
          type="danger"
          @click="deleteData"
        >
          删除
        </el-button>

        <uploadExcel down-url="客户映射关系导入模版.xlsx" :upload-aciton="bt" />
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="primary"
          @click="exPort"
        >
          导出
        </el-button>
        <el-button size="mini" type="primary" @click="openColumnSettings">
          设置列
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 320px)">
      <vxe-table
        id="customerMappingDraftTable"
        ref="tableRef"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :custom-config="customConfig"
        :data="list"
        height="100%"
        :row-config="{ isHover: true, isCurrent: true, height: 35 }"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="setSelectRows"
        @checkbox-change="setSelectRows"
      >
        <vxe-column
          align="center"
          field="selectOption"
          fixed="left"
          type="checkbox"
          width="55"
        />
        <vxe-column
          align="center"
          fixed="left"
          title="序号"
          type="seq"
          width="70"
        >
          <template #default="{ seq }">
            {{ seq + (queryForm.pageNum - 1) * queryForm.pageSize }}
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="cusProductCnName"
          min-width="140"
          title="客户中文品名"
        />
        <vxe-column
          align="center"
          field="cusProductEnName"
          min-width="140"
          title="客户英文品名"
        />
        <vxe-column
          align="center"
          field="reportCnName"
          min-width="200"
          title="申报中文品名"
        />
        <vxe-column
          align="center"
          field="reportEnName"
          min-width="220"
          title="申报英文品名"
        />
        <vxe-column
          align="center"
          field="hsCode"
          min-width="120"
          title="中国海关编码"
        />
        <vxe-column
          align="center"
          field="reportFactor"
          min-width="140"
          title="中国申报要素"
        />
        <vxe-column
          align="center"
          field="materialCn"
          min-width="140"
          title="中文材质"
        />
        <vxe-column
          align="center"
          field="materialEn"
          min-width="200"
          title="英文材质"
        />
        <vxe-column align="center" field="useTo" min-width="200" title="用途" />
        <vxe-column
          align="center"
          field="description"
          min-width="200"
          title="商品描述"
        />
        <vxe-column
          align="center"
          field="category"
          min-width="200"
          title="大类"
        />
        <vxe-column
          align="center"
          field="chapter"
          min-width="200"
          title="章节"
        />
        <vxe-column
          align="center"
          field="items"
          min-width="200"
          title="商品名称"
        />
        <vxe-column
          align="center"
          field="regulatoryCondition"
          min-width="200"
          title="监管条件"
        />
        <vxe-column
          align="center"
          field="inspection"
          min-width="200"
          title="检验检疫"
        />
        <vxe-column
          align="center"
          field="statusName"
          min-width="80"
          title="状态"
        >
          <template #default="{ row }">
            <span v-if="row.status === 0">
              <el-tag type="info">{{ row.statusName }}</el-tag>
            </span>
            <span v-if="row.status === 1">
              <el-tag type="info">{{ row.statusName }}</el-tag>
            </span>
            <span v-if="row.status === 2">
              <el-tag type="success">{{ row.statusName }}</el-tag>
            </span>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="createBy"
          min-width="100"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="dataSourceName"
          min-width="100"
          title="数据来源"
        />
        <vxe-column
          align="center"
          field="createTime"
          min-width="200"
          title="创建时间"
        />
        <vxe-column
          align="center"
          field="transactionUrl"
          fixed="right"
          min-width="80"
          title="链接"
        >
          <template #default="{ row }">
            <a :href="row.transactionUrl" target="_blank">
              {{ row.transactionUrl }}
            </a>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="option"
          fixed="right"
          title="操作"
          width="150"
        >
          <template #default="{ row }">
            <el-button style="padding: 0" type="text" @click="addOrEdit(row)">
              编辑
            </el-button>
            <el-button
              :disabled="!row.pictureUrl"
              style="padding: 0"
              type="text"
              @click="preview(row)"
            >
              图片预览
            </el-button>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <div v-if="isDetailsShow" class="posit">
      <span
        style="
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 9999;
          cursor: pointer;
          font-size: 15px;
          font-weight: 600;
        "
        @click="formSmbiClose"
      >
        返回上一级
        <vab-icon icon="arrow-go-back-line" />
      </span>
      <Details
        :id="id"
        ref="details"
        :dialog_visible="isDetailsShow"
        @dialogVisibleEvent="formSmbiClose"
      />
    </div>
    <table-edit
      ref="edit"
      :modulelist="queryList"
      :system-guid="queryForm.systemGuid"
    />
    <Preview ref="preview" />
  </div>
</template>

<script>
  import {
    changeStatusCustomerMapping,
    customerMappingDraft,
    draftDelete,
    draftExport,
    draftImportForUpdate,
  } from '@/api/cmsApi'
  import uploadExcel from '@/components/uploadExcel'
  import tableConfigMixin from '@/mixins/tableConfigMixin'
  import Preview from '@/vab/components/Preview'
  import VabQueryFormTopPanel from '@/vab/components/VabQueryForm/components/VabQueryFormTopPanel.vue'
  import VabQueryForm from '@/vab/components/VabQueryForm/index.vue'
  import TableEdit from './components/addOrEdit'
  import Details from './components/details'

  export default {
    name: 'BasicFiles',
    components: {
      VabQueryFormTopPanel,
      VabQueryForm,
      TableEdit,
      Details,
      Preview,
      uploadExcel,
    },
    mixins: [tableConfigMixin],
    data() {
      return {
        riskTypeList: [
          { id: 1, name: '低' },
          { id: 2, name: '中' },
          { id: 3, name: '高' },
        ],
        acceptabilityList: [
          { id: 1, name: '是' },
          { id: 2, name: '否' },
        ],
        id: '',
        list: [],
        fold: false,
        loadingExPort: false,
        listLoading: false,
        isDetailsShow: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        height: this.$baseTableHeight(3) + 50,
        queryForm: {
          cusProductCnName: '',
          cusProductEnName: '',
          reportCnName: '',
          reportEnName: '',
          hsCode: '',
          importHsCode: '',
          riskType: '',
          acceptability: '',
          rateOfDuty: '',
          surcharge: '',
          pageNum: 1,
          pageSize: 30,
          idList: [],
          sortName: '',
          sortRule: '',
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
      }
    },

    created() {
      this.queryList()
    },
    methods: {
      indexMethod(index) {
        return (
          (this.queryForm.pageNum - 1) * this.queryForm.pageSize + index + 1
        )
      },
      tableSortChange(data) {
        this.queryForm.sortName = data.prop
        if (data.order == 'ascending') {
          this.queryForm.sortRule = 1
        } else if (data.order == 'descending') {
          this.queryForm.sortRule = 2
        } else {
          this.queryForm.sortName = ''
          this.queryForm.sortRule = ''
        }
        console.log(data)
        this.queryList()
      },
      formSmbiClose() {
        this.isDetailsShow = false
      },
      details(row) {
        this.$refs.tableSort.toggleRowSelection(row, true)
        this.isDetailsShow = true
        this.id = row.id
      },
      //新增、编辑
      addOrEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      //图片预览
      preview(row) {
        this.$refs['preview'].showEdit(row)
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      setSelectRows({ records }) {
        this.queryForm.idList = records.map((item) => item.id)
      },
      rowStyle({ row }) {
        var arr = this.queryForm.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await customerMappingDraft(this.queryForm)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      handleFold() {
        this.fold = !this.fold
      },
      deleteData() {
        let data = {
          idList: this.queryForm.idList,
        }
        this.$baseConfirm('你确定全部【删除】勾选数据吗？', null, async () => {
          const res = await draftDelete(data)
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.queryList()
        })
      },
      changeStatus(status) {
        let data = {
          idList: this.queryForm.idList,
          status: status,
        }
        if (status == 1) {
          this.$baseConfirm(
            '你确定全部【启用】勾选数据吗？',
            null,
            async () => {
              const res = await changeStatusCustomerMapping(data)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              await this.queryList()
            }
          )
        } else {
          this.$baseConfirm(
            '你确定全部【停用】勾选数据吗？',
            null,
            async () => {
              const res = await changeStatusCustomerMapping(data)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              await this.queryList()
            }
          )
        }
      },
      beforeAvatarUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension = testmsg === 'xlsx'
        const extensionTwo = testmsg === 'xls'
        if (!extension && !extensionTwo) {
          this.$message.error('上传文件只能是.xls/.xlsx格式!')
          return false
        }
        return true
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      // 处理导出逻辑
      async handleExport(exportFunction, loadingState) {
        try {
          if (!this.queryForm.idList.length) {
            this.$message.warning('请勾选导出数据')
            return
          }
          this[loadingState] = true
          const res = await exportFunction(this.queryForm)
          this[loadingState] = false
          this.jump(res.data)
        } catch (error) {
          this[loadingState] = false
          this.$message.error('导出失败!')
        }
      },
      exPort() {
        this.handleExport(draftExport, 'loadingExPort')
      },
      handleAvatarSuccessImg(params) {
        this.file.push(params.file)
        this.bt()
      },
      async bt(form) {
        try {
          const res = await draftImportForUpdate(form)
          this.jump(res.data)
          this.clone()
        } catch (error) {
          this.clone()
        }
      },
      clone() {
        this.queryList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }

  ::v-deep {
    .left-panel {
      margin: 0px !important;
    }

    .el-dialog__body {
      text-align: center;
    }
  }

  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 99;
  }

  ::v-deep .rowStyle {
    background-color: #e8f7fd !important;
    color: #1890ff;
  }

  .columns-list {
    display: flex;
    flex-wrap: wrap;
  }

  .columns-list .el-checkbox {
    flex: 0 0 calc(20% - 10px); /* 每个复选框占1/5宽，减去间距 */
    margin: 5px;
    text-align: left; /* 首字符对齐 */
  }

  .columns-list .first-in-row {
    margin-left: 0; /* 第一个元素没有左边距 */
  }

  /* 确保全选复选框和按钮在同一行 */
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer .el-checkbox {
    margin-right: auto; /* 将全选复选框推到左侧 */
  }
</style>
